/*----------------------------------------------
* Login
----------------------------------------------*/

#login .logo-container {
    width: 130px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.login-form-container {
    width: 300px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.login-form-container .form-header {
    text-align: center;
    margin-bottom: 20px;
}

.login-form-container form button {
    width: 100%;
}

body#login .forgot-password-container {
    text-align: center;
    margin-top: 20px;
}

body#login .forgot-password-container .forgot-password-link {
    padding-bottom: 3px;
    border-bottom: 1px dotted;
    display: inline-block;
}

/*------------------------------------------------
* Admin Timesheet
------------------------------------------------*/

.admin-timesheet-setting {
    margin-top: 30px;
    float: left;
    width: 100%;
}

.admin-timesheet-setting table.admin-timesheet-setting-table {
    width: 100%;
}

.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td {
    padding: 10px;
    border: 0;
    background-color: transparent;
}

.admin-timesheet-setting table.admin-timesheet-setting-table thead tr th {
    padding: 10px;
    padding-bottom: 0;
    border: 0;
    background-color: transparent;
}

.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.day-activity-adder,
.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.actions-select {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.day-activity-adder li,
.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.actions-select li {
    display: inline-block;
    margin-right: 10px;
}

.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.day-activity-adder li:last-child,
.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.actions-select li:last-child {
    margin-right: 0;
}

.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.day-activity-adder li .add-activity,
.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.actions-select li .submit-activity {
    color: #46d700;
}

.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.day-activity-adder li .remove-activity,
.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.actions-select li .remove-activity {
    color: #e7002d;
}

.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.day-activity-adder li a,
.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.actions-select li a {
    display: block;
}

.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.hours-cell .form-control, .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.miles-cell .form-control {
    width: 75px;
    text-align: right;
}

.admin-timesheet-setting table.admin-timesheet-setting-table thead tr th.hours-cell, .admin-timesheet-setting table.admin-timesheet-setting-table thead tr th.miles-cell {
    width: 95px;
}

.admin-timesheet-setting table.admin-timesheet-setting-table thead tr th.mark-cell {
    width: 33px;
}

.admin-timesheet-setting table.admin-timesheet-setting-table thead tr th.date-cell, .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.date-cell {
    width: 140px;
}

.admin-timesheet-setting table.admin-timesheet-setting-table thead tr th.day-cell, .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.day-cell {
    width: 105px;
}

.admin-timesheet-setting table.admin-timesheet-setting-table thead tr th.day-activity-adder-cell, .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.day-activity-adder-cell {
    width: 65px;
}

.admin-timesheet-setting table.admin-timesheet-setting-table thead tr th.actions-cell, .admin-timesheet-setting table.admin-timesheet-setting-table tbody trtd.actions-cell {
    width: 80px;
}

.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td .form-control {
    padding: 4px 10px;
    line-height: 1.45;
}

.admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td textarea {
    height: 30px;
}

.admin-timesheet-setting .btn-container {
    float: right;
    margin-top: 30px;
}

.admin-timesheet-setting .btn-container .btn-entry .save-enter {
    background-color: #46d700;
}

.admin-timesheet-setting .btn-container .btn-entry .save-enter:hover {
    background-color: #36A800;
}

/*---------------------------------------
* PM Timesheet
---------------------------------------*/

.pm-timesheet-container {
    float: left;
    width: 100%;
    margin-top: 30px;
}

.pm-timesheet-container table.pm-timesheet-table {
    width: 100%;
}

table.pm-timesheet-table thead tr th.emp-no-cell {
    width: 80px;
}

table.pm-timesheet-table thead tr th.wk-cell {
    width: 55px;
}

table.pm-timesheet-table thead tr th.hrs-cell {
    width: 90px;
}

table.pm-timesheet-table thead tr th.ot-cell {
    width: 70px;
}

table.pm-timesheet-table thead tr th.gen-exp-cell {
    width: 110px;
}

table.pm-timesheet-table thead tr th.oot-exp-cel {
    width: 85px;
}

table.pm-timesheet-table thead tr th.oot-meal-cell {
    width: 125px;
}

table.pm-timesheet-table thead tr th.vat-cell {
    width: 90px;
}

table.pm-timesheet-table thead tr th.holiday-cell {
    width: 105px;
}

table.pm-timesheet-table thead tr th.milage-cell {
    width: 75px;
}

table.pm-timesheet-table thead tr th.name-cell i {
    float: right;
    line-height: 1.7;
}

table.pm-timesheet-table tbody tr td .status.approved {
    color: #46d700;
}

table.pm-timesheet-table tbody tr td .status.declined {
    color: #e7002d;
}

table.pm-timesheet-table thead tr th.status-cell {
    width: 85px;
}

table.pm-timesheet-table tbody tr td.emp-no-cell {
    text-align: center;
}

table.pm-timesheet-table tbody tr td.wk-cell {
    text-align: center;
}

.pm-timesheet .pagination {
    float: right;
    margin-top: 30px;
}

/*------------------------------------------
* User Management
------------------------------------------*/

.user-management {
    float: left;
    width: 100%;
    margin-top: 30px;
}

.user-management table.user-management-table {
    width: 100%;
}

.user-management table.user-management-table thead tr th.emp-no-cell,
.job-management table.job-management-table thead tr th.emp-no-cell {
    width: 80px;
}

.user-management table.user-management-table thead tr th.wk-cell {
    width: 55px;
}

.user-management table.user-management-table tbody tr td.emp-no-cell,
.job-management table.job-management-table tbody tr td.emp-no-cell {
    text-align: center;
}

.user-management table.user-management-table tbody tr td.wk-cell {
    text-align: center;
}

.user-management table.user-management-table thead tr th.actions-cell {
    width: 140px;
    text-align: center;
}

.user-management table.user-management-table tbody tr td.actions-cell,
.job-management table.job-management-table tbody tr td.actions-cell {
    text-align: center;
}

.user-management .pagination {
    float: right;
    margin-top: 30px;
}

#user-management .add-user-btn-container {
    float: left;
}

#user-management .search-container {
    float: right;
}

#user-management .search-container .search {
    position: relative;
}

#user-management .search-container .search label {
    position: absolute;
    color: #aaa;
    top: 9px;
    left: 15px;
}

#user-management .search-container .search .form-control {
    padding-left: 38px;
}

/*--------------------------------------
* Job Management
--------------------------------------*/

table.job-management-table {
    width: 100%;
}

.job-management {
    float: left;
    width: 100%;
    margin-top: 30px;
}

.job-management table.job-management-table thead tr th.actions-cell {
    width: 75px;
    text-align: center;
}

/*-------------------------------------
* Dashboard
-------------------------------------*/

#dashboard .user-management,
#dashboard .job-management,
#dashboard .activity-log-content,
#dashboard .pm-timesheet {
    margin-top: 20px;
}

#dashboard .view-more-btn-container {
    text-align: right;
    margin-top: 20px;
}

#dashboard .user-management-container,
#dashboard .job-management-container {
    margin-bottom: 30px;
    float: left;
    width: 100%;
}

#dashboard .pm-timesheet-container {
    margin-bottom: 0;
    float: left;
    width: 100%;
    margin-top: 0;
}

table.activity-log-table thead tr th.date-cell {
    width: 150px;
}

table.activity-log-table tbody tr td.date-cell, table.activity-log-table tbody tr td.user-cell {
    font-weight: bold;
}

table.activity-log-table tbody tr td {
    vertical-align: top;
}

#dashboard .activity-log-content {
    overflow-y: scroll;
    padding-right: 15px;
}

#dashboard .activity-log-content::-webkit-scrollbar {
    width: 8px;
}
   
#dashboard .activity-log-content::-webkit-scrollbar-track {
    background-color: #aaa;
    border-radius: 4px;
}
   
#dashboard .activity-log-content::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 4px;
}

.system-message-container {
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #f2f2f2;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 30px;
}

.system-message-btn-container.new-message .btn:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #950000;
    display: inline-block;
    vertical-align: top;
    margin-left: 7px;
    margin-top: 4px;
}

#dashboard .tables-col .col-content {
    float: left;
    width: 100%;
}

/*------------------------------------
* Media Query
------------------------------------*/

@media (max-width: 1199px) {
    .pm-timesheet {
        width: 450px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .pm-timesheet-container table.pm-timesheet-table thead {
        display: none;
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td, 
    .pm-timesheet-container table.pm-timesheet-table tbody tr, 
    .pm-timesheet-container table.pm-timesheet-table tbody, 
    .pm-timesheet-container table.pm-timesheet-table {
        display: block;
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td {
        text-align: left;
        border-bottom: 0;
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td:nth-child(odd) {
        background-color: #f2f2f2;
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td:nth-child(even) {
        background-color: #fff;
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td:last-child {
        border-bottom: 1px solid #ccc;
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr {
        margin-bottom: 20px;
        box-shadow: rgba(0,0,0,0.15) 0 0 10px;
        -webkit-box-shadow: rgba(0,0,0,0.15) 0 0 10px;
        -moz-box-shadow: rgba(0,0,0,0.15) 0 0 10px;
        -o-box-shadow: rgba(0,0,0,0.15) 0 0 10px;
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr:last-child {
        margin-bottom: 0;
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td:before {
        content: '';
        width: 110px;
        display: inline-block;
        padding-right: 10px;
        font-weight: bold;
        text-align: right;
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.emp-no-cell:before {
        content: 'Employee No.';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.wk-cell:before {
        content: '2WK';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.name-cell:before {
        content: 'Name';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.hrs-cell:before {
        content: 'Basic Hours';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.ot-cell:before {
        content: 'OT Hours';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.gen-exp-cell:before {
        content: 'General Exp.';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.milage-cell:before {
        content: 'Mileage';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.oot-exp-cel:before {
        content: 'OOT Exp.';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.oot-meal-cell:before {
        content: 'OOT Meal Rate';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.vat-cell:before {
        content: 'VAT';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.holiday-cell:before {
        content: 'Holiday Pay';
    }

    .pm-timesheet-container table.pm-timesheet-table tbody tr td.status-cell:before {
        content: 'Status';
    }

    .pm-timesheet .pagination {
        margin-top: 20px;
    }
}

@media (max-width: 991px) {
    #timesheet #main-content .container {
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .admin-timesheet-setting {
        margin-top: 20px;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table thead {
        display: none;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table, 
    .admin-timesheet-setting table.admin-timesheet-setting-table tbody, 
    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr, 
    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td {
        display: block;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr {
        position: relative;
        width: 450px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.activity-adder-mark-cell {
        position: absolute;
        z-index: 2;
        left: 0;
        width: auto!important;
        padding-left: 10px;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.activity-adder-mark-cell ul.day-activity-adder {
        display: inline-block;
        margin-right: 10px;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.activity-adder-mark-cell input[type="checkbox"] {
        margin-top: 4px;
        vertical-align: top;
        display: inline-block;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td {
        padding: 5px 10px;
        padding-left: 93px;
        width: 100%!important;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.activity-adder-mark-cell:before {
        display: none;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td:before {
        content: '';
        display: inline-block;
        width: 60px;
        font-weight: bold;
        text-align: right;
        padding-right: 10px;
        vertical-align: top;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td .date {
        display: inline-block;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td .date .day {
        display: inline-block!important;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td .form-control {
        display: inline-block;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.hours-cell .form-control, .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.miles-cell .form-control {
        text-align: left;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.activity-cell .form-control {
        width: calc(100% - 64px);
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.date-cell:before {
        content: 'Date';
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.hours-cell:before {
        content: 'Hours';
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.miles-cell:before {
        content: 'Mileage';
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.activity-cell:before {
        content: 'Activity';
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.actions-cell:before {
        content: 'Actions';
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.day-activity-adder, .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.actions-select {
        display: inline-block;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td textarea {
        height: 60px;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr {
        padding-bottom: 10px;
        border-bottom: 1px dotted #ccc;
        margin-bottom: 10px;
    }

    .admin-timesheet-setting .btn-container {
        float: none;
        width: 450px;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        margin-top: 30px;
        text-align: center;
    }

    #timesheet .check-status-container {
        margin-top: 20px;
    }

    .check-status-container .form-control {
        width: 200px;
        max-width: 100%;
    }

    .pm-timesheet-container {
        margin-top: 20px;
    }

    .user-management table.user-management-table thead {
        display: none;
    }

    .user-management table.user-management-table tbody tr td, 
    .user-management table.user-management-table tbody tr, 
    .user-management table.user-management-table tbody, 
    .user-management table.user-management-table {
        display: block;
    }

    .user-management table.user-management-table tbody tr {
        margin-bottom: 20px;
        box-shadow: rgba(0,0,0,0.15) 0 0 10px;
        -webkit-box-shadow: rgba(0,0,0,0.15) 0 0 10px;
        -moz-box-shadow: rgba(0,0,0,0.15) 0 0 10px;
        -o-box-shadow: rgba(0,0,0,0.15) 0 0 10px;
    }

    .user-management table.user-management-table tbody tr:last-child {
        margin-bottom: 0;
    }

    .user-management table.user-management-table tbody tr td:nth-child(odd) {
        background-color: #f2f2f2;
    }

    .user-management table.user-management-table tbody tr td:nth-child(even) {
        background-color: #fff;
    }

    .user-management table.user-management-table tbody tr td {
        border-bottom: 0;
        text-align: left!important;
    }

    .user-management table.user-management-table tbody tr td:last-child {
        border-bottom: 1px solid #ccc;
    }

    .user-management table.user-management-table tbody tr td:before {
        content: '';
        font-weight: bold;
        text-align: right;
        display: inline-block;
        padding-right: 10px;
        width: 105px;
    }

    .user-management table.user-management-table tbody tr td.emp-no-cell:before {
        content: 'Employee No.';
    }

    .user-management table.user-management-table tbody tr td.wk-cell:before {
        content: '2WK';
    }

    .user-management table.user-management-table tbody tr td.name-cell:before {
        content: 'Name';
    }

    .user-management table.user-management-table tbody tr td.role-cell:before {
        content: 'Role';
    }
    
    .user-management table.user-management-table tbody tr td.actions-cell:before {
        content: 'Actions';
    }

    .user-management table.user-management-table tbody tr td ul.actions {
        display: inline-block;
    }

    .user-management .pagination {
        margin-top: 20px;
    }
}

@media (max-width: 575px) {
    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.day-activity-adder li, .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td ul.actions-select li {
        margin-right: 5px;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.activity-adder-mark-cell ul.day-activity-adder {
        margin-right: 5px;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td {
        padding: 5px;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td {
        padding: 5px;
        padding-left: 73px;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td:before {
        padding-right: 5px;
        width: 55px;
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td.activity-cell .form-control {
        width: calc(100% - 59px);
    }

    .admin-timesheet-setting table.admin-timesheet-setting-table tbody tr td .date .day {
        margin-left: 0px!important;
    }

    .btn-container .btn-entry {
        display: block;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .btn-container .btn-entry:last-child {
        margin-bottom: 0;
    }

    .btn-container .btn-entry .btn {
        width: 320px;
        max-width: 100%;
    }

    #user-management .add-user-btn-container {
        display: block;
        float: none;
        margin-bottom: 20px;
    }

    #user-management .search-container {
        float: none;
    }

    #user-management .search-container .search {
        width: 210px;
        max-width: 100%;
    }

    .user-management {
        margin-top: 20px;
    }
}