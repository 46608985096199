@media (min-width: 576px) {
  .nav-user-menu .user-image {
    float: none;
    line-height: 10px;
    margin-top: -8px;
  }
}

.nav-user-menu .user-image {
  border-radius: 50%;
  float: left;
  height: 2rem;
  margin-top: -4px;
  width: 2rem;
}

.form-group.required .col-form-label:after {
  color: #d00;
  content: "*";
  position: absolute;
  margin-left: 3px;
}

/* Sweet alert */
.swal2-title {
  display: inline-block !important;
}

body.swal2-height-auto {
  height: 100vh !important;
}

.swal2-popup.swal2-toast {
  padding: 10px 12px !important;
  display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
  text-align: left !important;
  margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
  margin-bottom: 4px !important;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 9999 !important;
}

.theme-green .bs-datepicker-head {
  background-color: #009590;
}

.bs-datepicker-container {
  padding: 0 !important;
}

.bs-datepicker-body table td span.disabled, 
.bs-datepicker-body table td.disabled span {
  color: #9aaec1;
}

.theme-green .bs-datepicker-body {
  table {
    border-collapse: collapse;

    thead th,
    tbody td {
      border: none;
      background: transparent;

      > span.selected,
      &.selected span,
      > span[class*="select-"]:after,
      [class*="select-"] span:after {
        background-color: #009590;
      }

      &.disabled span {
        color: #9aaec1 !important;
      }
    }

    td[class*="select-"] span:after {
      background-color: #009590;
    }

    td span.disabled, 
    td.disabled span {
      color: #9aaec1 !important;
    }
  }
}

div.wrapper,
div.content-wrapper,
div.sidebar {
  min-height: 100vh;
}

#main-content {
  min-height: calc(100vh - 170px);
}

.freeze-ui {
  z-index: 9999 !important;
}

.freeze-ui:before {
  content: "";
  display: block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: transparent #00958f #00958f !important;
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 25px);
  will-change: transform;
  -webkit-animation: spin 0.75s infinite ease-in-out;
  animation: spin 0.75s infinite ease-in-out;
}

.menu-container .menu-content {
  overflow-y: auto;
}

.user-management table.user-management-table thead tr th.actions-cell,
.pm-timesheet table.pm-timesheet-table thead tr th.actions-cell {
  width: 140px;
  text-align: center;
}

.user-management table.user-management-table tbody tr td.actions-cell, 
.pm-timesheet table.pm-timesheet-table tbody tr td.actions-cell {
  text-align: center;
}

table.pm-timesheet-table tbody tr td .status.completed {
  color: #46d700;
}

table.pm-timesheet-table tbody tr td .status.rejected {
  color: #e7002d;
}

//
div.multi-selection-list {
  padding: 10px;
  background: #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #ccc;
  max-height: 400px;
  overflow-y: auto;

  label.custom-control-label {
    padding: 0px 10px !important;
    width: 100%;
  }

  label.custom-control-label:hover {
    color: #fff;
    background-color: #00958f;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00958f;
    background-color: #00958f;
  }

  .custom-control-input:checked ~ .custom-control-label {
    color: #fff;
    border-color: #00958f;
    background-color: #00958f;
    width: 100%;
  }
}

.search-field-container {
  label {
    position: absolute;
    top: 10px;
    left: 25px;
    color: #7a8187;
  }
  input {
    padding-left: 30px;
  }
}

table.pm-timesheet-table thead tr  {
  th {

      &.hrs-cell {
          min-width: 100px;
      }
      &.ot-cell {
          min-width: 80px;
      }
      &.gen-exp-cell,
      &.holiday-cell {
        width: 115px;
      }
      &.milage-cell {
        width: 90px;
      }
      &.oot-exp-cel {
        width: 95px;
      }
      &.vat-cel {
        width: 60px;
      }
  }
}

.timelog-extra-fields {

  td {
    border: none;
    background-color: transparent !important;

    label {
      padding-top: 10px;
      text-align: right; 
      width: 100%;
    }
  }
}

.disabled {
  color: #6c757d !important;
  cursor: default;
}

.camera-app {
  padding: 15px 0;

  .image-preview {
    width: 100%;
  }
}

app-camera {
  .webcam-wrapper {
    width: 100% !important;
  }

  video {
    width: 100% !important;
    height: 100% !important;
  }
}

// Week picker
.week-picker {
  font-weight: 700;
  text-transform: uppercase;
  color: #009590;
  font-size: 20px;
  line-height: 20px;
}

.text-theme-color {
  color: #009590;
}

.bg-theme-color {
  background-color: #009590 !important;

  td {
    background-color: #009590 !important;
    color: #fff !important;
  }
}


.bg-disabled-color {
  background-color: #e9ecef !important;
  
  &.approved {
    color: #00958f !important;

    input, select {
      color: #00958f !important;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #00958f!important;
    }
    
    .custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgb(0, 149, 143, 0.25)
    }
    .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
    }
    .custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
      background-color: #C8FFC8; 
    }
  }
}

.row-disabled {
  select {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.form-group-currency {
  position: relative;

  span {
    position: absolute;
    top: 8px;
    left: 15px;
    font-weight: bold;
    font-size: 16px;
  }

  input {
    padding-left: 30px;
  }

  
}


input.ng-invalid {
  color: #dc3545 !important;
  border: 1px solid #dc3545;
}


.timesheet-card {
  .freeze-ui {
      z-index: 1 !important;
  }
}

timepicker table tbody {
  tr:hover {
    background-color: transparent;

    td {
      background-color: inherit;
    }
  }
  td {
    border: none;
    padding: 0 3px;

    .form-control {
      height: 40px !important;
    }
  }
  td:nth-of-type(4) {
    display: none !important;
  }
  
}


app-printable-layout {
  /* hide the printing component from @media screen */
  display: none;
}

@media print {
  /* invert the display (show/hide) properties of the main */
  /* aplication component and the printing component       */

  app-printable-layout {
    display: block !important;
  }

  app-root {
    display: none;
  }
}
@media print {
  :host {
    display: none;
  }
}

.bg-primary {
  background: #009590 !important;
}

@media print {
  angular-image-viewer .img-container {
      height: auto !important;
  }

  .attached-receipts, 
  .view-timesheet-details,
  .attached-receipt {
    border: none !important;

    .modal-content,
    .modal-header,
    .modal-body,
    .modal-footer {
      border: none !important;
    }
  }
}